import axios from "axios";
import Swal from "sweetalert2";

const username = "idetech_dev";
const password = "5720115114";
const account = btoa(`${username}:${password}`);

axios.interceptors.request.use((config) => {
  config.baseURL = "https://api.demoku.web.id/indikator";
  // config.baseURL = "https://api.test/indikator";

  config.headers.Authorization = `Basic ${account}`;

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;

    if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }

    return Promise.reject(error);
  }
);
