import Vue from "vue";
import App from "./App.vue";
import router from "./router/index";
import "./plugins/axios";
import VueGoodTable from "vue-good-table";
import "leaflet/dist/leaflet.css";

Vue.config.productionTip = false;

Vue.use(VueGoodTable);

new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
