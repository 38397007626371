<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="card mb-4 mt-4">
            <div class="card-header bg-orange">Kegiatan Harian</div>
            <div class="card-body">
              <div class="row">
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label for="">Tanggal Kegiatan</label>
                    <input
                      v-model="form.tanggal_kegiatan"
                      type="date"
                      class="form-control mt-2"
                      :class="
                        formValidate.tanggal_kegiatan ? ' is-invalid' : ''
                      "
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.tanggal_kegiatan"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label for="">Jam Mulai</label>
                    <input
                      v-model="form.jam_mulai"
                      type="time"
                      class="form-control mt-2"
                      :class="formValidate.jam_mulai ? ' is-invalid' : ''"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.jam_mulai"
                    ></div>
                  </div>
                </div>
                <div class="col-md-4 mt-2">
                  <div class="form-group">
                    <label for="">Jam Selesai</label>
                    <input
                      v-model="form.jam_selesai"
                      type="time"
                      class="form-control mt-2"
                      :class="formValidate.jam_selesai ? ' is-invalid' : ''"
                    />
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.jam_selesai"
                    ></div>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label for="">Uraian Kegiatan</label>
                    <textarea
                      v-model="form.uraian"
                      class="form-control mt-2"
                      :class="formValidate.uraian ? ' is-invalid' : ''"
                      rows="5"
                    ></textarea>
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.uraian"
                    ></div>
                  </div>
                </div>
                <div class="col-md-12 mt-2">
                  <div class="form-group">
                    <label for="">Permasalahan/Solusi/Hasil</label>
                    <textarea
                      v-model="form.hasil"
                      class="form-control mt-2"
                      :class="formValidate.hasil ? ' is-invalid' : ''"
                      rows="5"
                    ></textarea>
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.hasil"
                    ></div>
                  </div>
                </div>
                <div class="col-md-2 mt-2">
                  <div class="form-group">
                    <label>TPP</label>
                    <select
                      class="form-select"
                      :class="formValidate.tpp ? ' is-invalid' : ''"
                      v-model="form.tpp"
                    >
                      <option value="">-Pilih-</option>
                      <option value="1">Ya</option>
                      <option value="0">Tidak</option>
                    </select>
                    <div
                      class="invalid-feedback"
                      v-html="formValidate.tpp"
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <button
                class="btn btn-success float-end"
                :disabled="this.loading"
              >
                <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
                Simpan
              </button>
            </div>
          </div>
        </form>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  name: "FormPage",
  data() {
    return {
      form: {
        tanggal_kegiatan: "",
        jam_mulai: "",
        jam_selesai: "",
        menit: "",
        uraian: "",
        hasil: "",
        tpp: "",
      },
      formValidate: [],
      loading: false,
      userLevel: "user",
      userToken: "",
    };
  },
  created() {
    const user = localStorage.getItem("indikatorData");
    if (user) {
      this.user = JSON.parse(user);
      this.userLevel = this.user.level;
      this.userToken = this.user.token;
    }
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      formData.append("tanggal_kegiatan", this.form.tanggal_kegiatan);
      formData.append("jam_selesai", this.form.jam_selesai);
      formData.append("menit", this.form.menit);
      formData.append("jam_mulai", this.form.jam_mulai);
      formData.append("uraian", this.form.uraian);
      formData.append("hasil", this.form.hasil);
      formData.append("tpp", this.form.tpp);

      axios
        .post("/api/kegiatan/create/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.go(-1);
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>