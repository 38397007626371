var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"layoutSidenav_nav"}},[_c('nav',{staticClass:"sb-sidenav accordion sb-sidenav-dark",staticStyle:{"box-shadow":"0 4px 8px rgba(0, 0, 0, 0.2)"},attrs:{"id":"sidenavAccordion"}},[_c('div',{staticClass:"sb-sidenav-menu"},[_c('div',{staticClass:"nav"},[_c('div',{staticClass:"sb-sidenav-menu-heading"},[_vm._v("Menu")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active bg-active': _vm.link == 'dashboard',
          },attrs:{"to":"/dashboard"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"fas fa-tachometer-alt"})]),_vm._v(" Dashboard ")]),_c('router-link',{staticClass:"nav-link",class:{
            ' active bg-active': _vm.link == 'kegiatan-harian',
          },attrs:{"to":"/kegiatan-harian"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"fas fa-file"})]),_vm._v(" Kegiatan Harian ")]),(_vm.userLevel == 'admin')?_c('router-link',{staticClass:"nav-link",class:{
            ' active  bg-active': _vm.link == 'pengguna',
          },attrs:{"to":"/pengguna"}},[_c('div',{staticClass:"sb-nav-link-icon"},[_c('i',{staticClass:"fas fa-file-alt"})]),_vm._v(" Pengguna ")]):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }