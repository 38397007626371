<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <h3 class="mt-4 mb-3">Dashboard</h3>
      </div>
    </main>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "DashboardPage",
  data() {
    return {
      detail: {
        bangunan: "",
        saluran: "",
        jalan: "",
        galian: "",
      },
      loading: true,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("/api/dashboard")
        .then((response) => {
          for (let key in this.detail) {
            this.detail[key] = response.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "IrigationIcon";
  src: url("../assets/fonts/icomoon.woff") format("woff");
  /* tambahkan format lain jika diperlukan */
}
.card-body {
  height: 90px;
}

.card-body span {
  font-size: 20px;
  font-weight: bold;
}

.card-body i {
  font-size: 50px;
  float: right;
  margin-top: -35px;
}

.icon-saluran:before {
  font-family: "IrigationIcon";
  content: "\e900";
  font-style: normal;
}
.icon-bendungan:before {
  font-family: "IrigationIcon";
  content: "\e90e";
  font-style: normal;
}
.icon-galian:before {
  font-family: "IrigationIcon";
  content: "\e913";
  font-style: normal;
}
.icon-jalan:before {
  font-family: "IrigationIcon";
  content: "\e91e";
  font-style: normal;
}
.stretched-link {
  text-decoration: none;
}
</style>

