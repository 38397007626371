import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
import Swal from "sweetalert2";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";

import Pengguna from "../pages/pengguna/Index.vue";
import PenggunaForm from "../pages/pengguna/Form.vue";
import PenggunaEdit from "../pages/pengguna/Edit.vue";
import PenggunaPassword from "../pages/pengguna/Password.vue";

import Harian from "../pages/kegiatan-harian/Index.vue";
import HarianForm from "../pages/kegiatan-harian/Form.vue";
import HarianEdit from "../pages/kegiatan-harian/Edit.vue";
import HarianDetail from "../pages/kegiatan-harian/Detail.vue";

const routes = [
  { path: "/", component: Login },
  { path: "*", component: Login },
  { path: "/dashboard/", component: Dashboard },
  { path: "/login/", component: Login },
  { path: "/kegiatan-harian/", component: Harian },
  { path: "/kegiatan-harian/create", component: HarianForm },
  { path: "/kegiatan-harian/update/:id", component: HarianEdit },
  { path: "/kegiatan-harian/detail/:id", component: HarianDetail },

  { path: "/pengguna/", component: Pengguna, meta: { requiredLevel: "admin" } },
  {
    path: "/pengguna/create",
    component: PenggunaForm,
    meta: { requiredLevel: "admin" },
  },
  { path: "/pengguna/update/:id", component: PenggunaEdit },
  { path: "/pengguna/password/:id", component: PenggunaPassword },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("indikatorData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (!isLoggedIn && to.path !== "/login" && to.path !== "/") {
    next("/");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const userData = localStorage.getItem("surveillanceData");
  if (userData) {
    const user = JSON.parse(userData);

    axios
      .get("/api/auth/status/", {
        headers: {
          token: user.token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("surveillanceData");
            if (to.path !== "/") {
              next("/");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
