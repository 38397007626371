<template>
  <div id="layoutSidenav_nav">
    <nav
      class="sb-sidenav accordion sb-sidenav-dark"
      id="sidenavAccordion"
      style="box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2)"
    >
      <div class="sb-sidenav-menu">
        <div class="nav">
          <div class="sb-sidenav-menu-heading">Menu</div>
          <router-link
            class="nav-link"
            to="/dashboard"
            :class="{
              ' active bg-active': link == 'dashboard',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="fas fa-tachometer-alt"></i>
            </div>
            Dashboard
          </router-link>
          <router-link
            class="nav-link"
            to="/kegiatan-harian"
            :class="{
              ' active bg-active': link == 'kegiatan-harian',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="fas fa-file"></i>
            </div>
            Kegiatan Harian
          </router-link>
          <router-link
            v-if="userLevel == 'admin'"
            class="nav-link"
            to="/pengguna"
            :class="{
              ' active  bg-active': link == 'pengguna',
            }"
          >
            <div class="sb-nav-link-icon">
              <i class="fas fa-file-alt"></i>
            </div>
            Pengguna
          </router-link>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
export default {
  name: "SideBar",
  data() {
    return {
      link: "dashboard",
      isMasterMenuOpen: false,
      userName: "Administrator",
      userEmail: "admin@admin.com",
      userLevel: "user",
    };
  },
  created() {
    const user = localStorage.getItem("indikatorData");
    if (user) {
      this.user = JSON.parse(user);
      this.userName = this.user.nama;
      this.userEmail = this.user.email;
      this.userLevel = this.user.level;
    }
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newValue) {
        const parts = newValue.split("/");
        const link = parts[1];
        this.link = link;
        this.isMasterMenuOpen =
          this.link === "dosen" ||
          this.link === "ruang" ||
          this.link === "kelas" ||
          this.link === "mata-kuliah";
      },
    },
  },
};
</script>
<style scoped>
.nav-link {
  border-radius: 10px;
  margin: 0px 10px;
}
.bg-active {
  background-color: #d16710;
  transition: width 0.5s ease-in-out, background-color 0.5s linear;
}
</style>

