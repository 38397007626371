<template>
  <div>
    <main>
      <div class="container-fluid px-4">
        <div class="card mb-4 mt-4">
          <div class="card-header bg-orange">Detail Kegiatan Harian</div>
          <div class="card-body">
            <div class="rows">
              <table class="table table-striped">
                <tbody>
                  <tr>
                    <td>Tanggal Kegiatan</td>
                    <td style="width: 5px">:</td>
                    <td>{{ formatTanggal(detail.tanggal_kegiatan) }}</td>
                  </tr>
                  <tr>
                    <td>Waktu</td>
                    <td>:</td>
                    <td>
                      {{ formatJam(detail.jam_mulai) }} -
                      {{ formatJam(detail.jam_selesai) }}
                    </td>
                  </tr>
                  <tr>
                    <td>Uraian Kegiatan</td>
                    <td>:</td>
                    <td>{{ detail.uraian }}</td>
                  </tr>
                  <tr>
                    <td>Permasalahan/Solusi/Hasil</td>
                    <td>:</td>
                    <td>{{ detail.hasil }}</td>
                  </tr>
                  <tr>
                    <td>Log</td>
                    <td>:</td>
                    <td>{{ formatFull(detail.dibuat) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <a class="btn btn-warning" href="javascript:history.go(-1);">
              Kembali
            </a>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import axios from "axios";
import moment from "moment";

export default {
  name: "FormPage",
  data() {
    return {
      detail: {
        id_kegiatan_harian: "",
        tanggal_kegiatan: "",
        jam_mulai: "",
        jam_selesai: "",
        menit: "",
        uraian: "",
        hasil: "",
        tpp: "",
        dibuat: "",
      },
      loading: false,
      key: "",
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    const id = this.$route.params.id;
    this.key = id;
    this.fetchData();
  },
  methods: {
    fetchData() {
      axios
        .get("/api/kegiatan/detail/" + this.key)
        .then((response) => {
          this.rows = response.data.data;
          this.detail.id_kegiatan_harian =
            response.data.data.id_kegiatan_harian;
          this.detail.tanggal_kegiatan = response.data.data.tanggal_kegiatan;
          this.detail.jam_mulai = response.data.data.jam_mulai;
          this.detail.jam_selesai = response.data.data.jam_selesai;
          this.detail.uraian = response.data.data.uraian;
          this.detail.hasil = response.data.data.hasil;
          this.detail.tpp = response.data.data.tpp;
          this.detail.dibuat = response.data.data.created_at;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    formatTanggal(dateTime) {
      return moment(dateTime).format("D MMMM YYYY");
    },
    formatFull(dateTime) {
      return moment(dateTime).format("D MMMM YYYY, HH:mm");
    },
    formatJam(dateTime) {
      return moment(dateTime, "HH:mm:ss").format("HH:mm");
    },
  },
};
</script>